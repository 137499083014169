import simpleRestProvider from './simpleRestProvider';
import {fetchUtils} from 'react-admin'


const httpClient:any = (url:string, options:any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const restProvider = simpleRestProvider(process.env.REACT_APP_API_URL?process.env.REACT_APP_API_URL:'http://localhost:9000', httpClient);

export default restProvider;
