import * as React from 'react';
import { FC,  useState, useEffect } from 'react';
import { useRefresh } from 'react-admin';
import {    
    Create,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    ArrayInput,
    SimpleFormIterator,
    RadioButtonGroupInput,
    SelectInput,
    BooleanInput,
    FormDataConsumer,
    useRedirect,
    useNotify
} from 'react-admin'; // eslint-disable-line import/no-unresolved


import { CreateComponentProps } from '../types';
import { makeStyles } from '@material-ui/core/styles';
import { modeType } from '../simpleRestProvider'

const useStyles = makeStyles({

    root: {
               
    },    
    form : {
        width: '60%',
    }
})

const initialAnswers = ["","","","","","",""];

const findRankMax = async (props:any) => {
    const access_token= localStorage.getItem('token')
    const responseJson = await fetch(`${process.env.REACT_APP_API_URL}${props.basePath}/id/last?access_token=${access_token}`).then((response) => response.json()).catch((e) => {return {rank : -1}})
    return responseJson.rank
}

const validateLevelCreation = (values:any) => {
    const errors:any = {};

   

    if (values.answers && (values.mode === modeType.PREDICTIVE || values.mode === modeType.NORMAL) && (values.answers.length < 7 || values.answers.includes(""))) {
        errors.answers = ['Must have at least 7 fullfilled answers'];
    }

    if(values.answers && (values.mode === modeType.PREDICTIVE || values.mode === modeType.NORMAL)) {
        if((new Set(values.answers)).size !== values.answers.length)
            errors.answers = ['Each word must be different'];
    }

    var format = /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/;
 

    if(values.answers && values.answers.some((a:string) => {return format.test(a)})) {
        errors.answers = ['Found special characters (parmi !@#$%^&*()_+=[]{};\':"\\|,.<>\/?] )  in anwsers']
    }

    return errors
};





const defaultValues = {answers:["","","","","","",""]};
const LevelCreate: FC<CreateComponentProps> = ({ permissions, ...props }:any) => {
    
    const classes = useStyles();
    const [maxRank, setMaxRank] = useState(0);
    const refresh = useRefresh();
    const redirect = useRedirect();
    const notify = useNotify();
    
    const country = props.resource.substr(23,2)

    const onSuccess = ({ data }:any) => {
        notify(`New level saved`)
        redirect(props.basePath);
        refresh();
    };

    useEffect( () => {
        findRankMax(props).then(max => {
            max++
            setMaxRank(max)
            refresh()
        })        
    })    
    return (
        
        <Create {...props} className={classes.root} onSuccess={onSuccess}>
            <SimpleForm className={classes.form} validate={country!=="AD"?validateLevelCreation:null}>                
               
                <NumberInput source="rank" label="Index" defaultValue={maxRank}/> 
                <TextInput autoFocus source="media" validate={required()} />
                <RadioButtonGroupInput source="mode" defaultValue={modeType.LEARNING} choices={[
                  { id: modeType.LEARNING, name: 'Learning' },
                  { id: modeType.NORMAL, name: 'Production with statistics (full keyboard)' },
                  { id: modeType.PREDICTIVE, name: 'Production' }
                ]} />

                <FormDataConsumer>
                    {({ formData, ...rest }:any) => {
                        if(formData.mode!==modeType.LEARNING) {
                                return (
                                <>
                                                               
                                <ArrayInput source="answers" label="Answers list" initialValue={initialAnswers}>
                                    <SimpleFormIterator>
                                        <TextInput label="Answer" initialValue="" />
                                    </SimpleFormIterator>
                                </ArrayInput>
                                </>
                                
                                )
                        }
                        else {
                            if(permissions !== "guest") 
                            return (                            
                                <>
                                <NumberInput source="nbAnswersLearning" label="Answers number" defaultValue={4} style={{width: "25%"}} inputProps={{
                                    step: 1,
                                    min: 0,
                                    max: 7
                                    }}/>
                                <br/>
                                <NumberInput source="indexLearning" label="Display index" defaultValue={0} style={{width: "25%"}} inputProps={{
                                        step: 1,
                                        min: 0,
                                        max: 9999
                                        }}/>
                                <BooleanInput source="priority" defaultValue="true" label="Learning display candidat"/>                            
                                </>
                            )
                        }
                    }}
                </FormDataConsumer> 
                                              
            </SimpleForm>
        </Create>
        
    );
};

export default LevelCreate;
