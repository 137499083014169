// in ./ResetViewsButton.js
import * as React from 'react';
import { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import IconCancel from '@material-ui/icons/Cancel';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MenuItem from '@material-ui/core/MenuItem';
import {
    fetchUtils,
    Button,
    Confirm,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';

import {countryList} from '../App'

const useStyles = makeStyles((theme) => ({
 
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const BulkCloneButton = (props) => {
    const { resource, showNotification,refreshView , selectedIds} = props;
    const { fetchJson } = fetchUtils;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [local, setLocal] = useState('');
    
  
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    

    const handleChangeLocal = (event) => {
        setLocal(event.target.value);
    };
    
   
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async () => {

        if(local.length>0) {
            const lang = resource.substr(12,2)
            const country = resource.substr(23,2)
            const toLang = local.substr(0,2)
            const toCountry = local.substr(3,2)
            const access_token= localStorage.getItem('token')

            
        
            try{
                
                const clonedLevel = await fetchJson(`${process.env.REACT_APP_API_URL}/levels/lang/${lang}/country/${country}/clone/to/${toLang}/${toCountry}?access_token=${access_token}`, {method: 'POST', body: JSON.stringify({clones:selectedIds})})
                setOpen(false);
                refresh();
                notify(`Levels cloned`);
                //unselectAll(resource);
                
            }  catch(e) {
                notify('Error: cloning failed', 'warning')
            }     
        }
        
    };


    const menuItems = countryList.map((c) => (  <MenuItem value={c.lang+"-"+c.country}>{c.lang+"-"+c.country}</MenuItem>))
    return (
        <Fragment>
            <Button label="Clone Levels" onClick={handleClick}><FileCopyIcon /></Button>
            <Dialog
                    fullWidth
                    open={open}
                    onClose={handleDialogClose}
                    aria-label="Clone Levels"
                >
                    <DialogTitle>Clone Levels</DialogTitle>
                    <DialogContent>
                    <Select
                            value={local}
                            onChange={handleChangeLocal}
                            displayEmpty
                            className={classes.selectEmpty}
                            >
                            <MenuItem value="" disabled>
                                Select local
                            </MenuItem>
                            {menuItems}
                    </Select>
                   
                    </DialogContent>
                    <DialogActions>
                        <Button
                            label="ra.action.confirm"
                            onClick={handleConfirm}
                        >
                            <FileCopyIcon />
                        </Button>
                        <Button
                            label="ra.action.cancel"
                            onClick={handleDialogClose}
                        >
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
        </Fragment>
    );
}

export default BulkCloneButton;