import React from 'react';
import Button from '@material-ui/core/Button';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import { connect } from 'react-redux';
import { parse as convertFromCSV } from 'papaparse';
import { fetchUtils , showNotification as showNotificationAction, refreshView} from 'react-admin';



const SwapLevelButton = (props) => {
    const { resource, showNotification,refreshView } = props;

    //levels/lang/en/country/IV
    const lang = resource.substr(12,2)
    const country = resource.substr(23,2)

    const handleSwap = (e) => {
        const { fetchJson } = fetchUtils;
        const file = e.target.files && e.target.files[0];
        const access_token= localStorage.getItem('token')        
        const local = resource.substr(-13)        
        
        let levelcheck = []
        if (file) {
            convertFromCSV(file, {
                complete: (result) => {
                    const { data } = result;      
                    
                    const swapData = data.filter(row => row[0] && row[1]).map(row => `${row[0]},${row[1]}`)
                    fetchJson(`${process.env.REACT_APP_API_URL}/levels/swap`, {method: 'PUT', body: JSON.stringify({lang:lang, country:country, swaps: swapData, access_token})})
                    .then(()=> {
                            setTimeout(function () {
                                refreshView()
                                showNotification("Swap levels done !")
                            }, 500)
                    })
                    .catch((e) => {
                        showNotification("Swap levels failed : incorrect levels data !")
                    })
                    
                }
            });
        }    
        
        e.target.value=null
        
    };

    return (
        <>
            <input
                type="file"
                id="swap-button"
                style={{ display: 'none' }}
                accept='.csv'
                onChange={handleSwap}
            />
            <label htmlFor="swap-button" style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Button
                    color="primary"
                    component="span"
                >
                    <SwapVerticalCircleIcon style={{fontSize: 20 }} />
                    <span style={{ paddingLeft: '0.5em' }}>Swap</span>
                </Button>
            </label>
        </>
    );
};


export default connect(null,{
    showNotification: showNotificationAction,
    refreshView
})(SwapLevelButton);