import * as React from 'react';
import { useState, useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

import { Login } from './layout';

import levels from './levels';
import countries from './countries';
import englishMessages from './i18n/en';

import {checkWriteAllow} from './components/utils';


export let countryList = []

const i18nProvider = polyglotI18nProvider(locale => {
    // if (locale === 'fr') {
    //     return import('./i18n/fr').then(messages => messages.default);
    // }
    // Always fallback on english
    return englishMessages;
}, 'en');


const fetchCountries = (permissions:any) =>
    {

        const access_token = localStorage.getItem('token')

        let routes = [<Resource  options={{ label: 'countries' }} name="countries" {...countries} />]
        
        return fetch(`${process.env.REACT_APP_API_URL}/countries?limit=200&page=1&access_token=${access_token}`)
            .then((response) => response.json())
            .then((responseJson) => {
                
                if (responseJson) {   

                   responseJson.rows.sort((a:any, b:any) => { 
                       if(a.idLang+a.idCountry > b.idLang+b.idCountry) return 1 
                       else return -1 
                    })
                   const countriesRoutes = responseJson.rows.map((c:any) => (c.redirection === undefined || c.redirection === '') 
                   && <Resource  options={{ label: 'levels-'+c.idLang+'-'+c.idCountry}} name={"levels/lang/"+c.idLang+"/country/"+c.idCountry} 
                        create= {checkWriteAllow(permissions,c.idLang, c.idCountry) ? levels.create : null}
                        edit= {checkWriteAllow(permissions,c.idLang, c.idCountry) ? levels.edit : null}
                        show= {levels.show}
                        list= {levels.list}
                        icon= {levels.icon}
                        permissions
                     />)

                   countryList = responseJson.rows.map((c:any) => ( { lang: c.idLang, country: c.idCountry }))

                   
                    if(permissions.role === "admin") routes = routes.concat(countriesRoutes) 
                    else  routes=countriesRoutes
                   
                  
                   return routes
                }
            })
            .catch((error) => {
                
                console.error(error);
                return routes
            });
    }
const App = () => {     


   

    
    
    //<Resource  options={{ label: 'countries' }} name="countries" {...countries} />
    return (
        <Admin
            title="Top 7"
            dataProvider={dataProvider}            
            authProvider={authProvider}
            loginPage={Login}
            i18nProvider={i18nProvider}
        >            
           
            {fetchCountries}
            
        </Admin>
    );
};

export default App;