import * as React from 'react';
import { FC, useRef, useCallback } from 'react';

import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    ArrayInput,
    SimpleFormIterator,
    RadioButtonGroupInput,
    SelectInput,
    BooleanInput,
    FormDataConsumer,

} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { EditComponentProps } from '../types';
import DisplayStatistics from '../components/DisplayStatistics';
import { makeStyles } from '@material-ui/core/styles';
import { modeType } from '../simpleRestProvider'


const drawerWidth = 600;
const useStyles = makeStyles({

    root: {

    },
    form: {
        width: '60%',
    }
})

const validateLevelCreation = (values: any) => {
    const errors: any = {};
    
    if (values.answers && (values.mode === modeType.PREDICTIVE || values.mode === modeType.NORMAL) && (values.answers.length < 7 || values.answers.includes(""))) {
        errors.answers = ['Must have at least 7 fullfilled answers'];
    }

    if(values.answers && (values.mode === modeType.PREDICTIVE || values.mode === modeType.NORMAL)) {
        if((new Set(values.answers)).size !== values.answers.length)
            errors.answers = ['Each word must be different'];
    }

    var format = /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/;


    if (values.answers && values.answers.some((a: string) => { return format.test(a) })) {
        errors.answers = ['Found special characters (parmi !@#$%^&*()_+=[]{};\':"\\|,.<>\/?] )  in anwsers']
    }

    return errors
};


const useFocus = () => {
    const htmlElRef = useRef<HTMLInputElement>(null)
    const setFocus = () => {
       
        htmlElRef?.current && htmlElRef.current.focus()
    }

    return [htmlElRef, setFocus]
}



    


const LevelEdit: FC<EditComponentProps> = ({ permissions, ...props }) => {
    const classes = useStyles();

    const country = props.resource.substr(23,2)
    const [inputRef, setInputFocus] = useFocus()

    return (

        <Edit className={classes.root} {...props} >
            <SimpleForm className={classes.form} validate={country!=="AD"?validateLevelCreation:null}>

                <NumberInput source="rank" label="Index" disabled={permissions === 'guest'} />
                
                <TextInput autoFocus source="media" inputRef={inputRef} validate={required()} />
                <RadioButtonGroupInput source="mode" defaultValue={modeType.LEARNING} choices={[
                    { id: modeType.LEARNING, name: 'Learning' },
                    { id: modeType.NORMAL, name: 'Production with statistics (full keyboard)' },
                    { id: modeType.PREDICTIVE, name: 'Production' },
                ]} />


                <FormDataConsumer>
                    {({ formData, ...rest }: any) => {


                        if (formData.mode !== modeType.LEARNING) {                            
                            return (
                                <>
                                   
                                    <ArrayInput source="answers" label="Answers list">
                                        <SimpleFormIterator>
                                            <TextInput label="Answer" initialValue="" />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </>

                            )
                        }
                        else {
                            if (permissions !== "guest")
                                return (
                                    <>
                                        <NumberInput source="nbAnswersLearning" label="Answers number" defaultValue={4} style={{ width: "25%" }} inputProps={{
                                            step: 1,
                                            min: 0,
                                            max: 7
                                        }} />
                                        <br />
                                        <NumberInput source="indexLearning" label="Display index" defaultValue={0} style={{ width: "25%" }} inputProps={{
                                            step: 1,
                                            min: 0,
                                            max: 9999
                                        }} />
                                        <BooleanInput source="priority" defaultValue="true" label="Learning display candidat" />
                                    </>
                                )
                        }
                    }}
                </FormDataConsumer>

               
                <DisplayStatistics setAnswers={setInputFocus} />
            </SimpleForm>
        </Edit>

    );
};

export default LevelEdit;
