export const checkWriteAllow = (permissions, lang, country) => {
    if (permissions === undefined || permissions === null) return false
    if(permissions.role === 'admin') {
        return true
    } else {
        if(permissions.allow.some((a) => {
            return a.permission === 'write' && a.locale.startsWith(lang) && (a.locale.endsWith(country) || a.locale.endsWith('*'))
        })) 
        {
            return true
        } else
            return false
    }
}

export default checkWriteAllow
