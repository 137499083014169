import * as React from 'react';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import { BulkUpdateButton } from 'react-admin';
import { modeType } from '../simpleRestProvider'
const levels = { mode: modeType.NORMAL};

const BulkForceStatistics = (props) => (
    <BulkUpdateButton
        {...props}
        label="Force Statistics"
        data={levels}
        icon={<KeyboardIcon/>}
    />
);

export default BulkForceStatistics;