
import React from 'react';
import {TextField, BooleanField, BooleanInput, ReferenceInput,ReferenceField,SelectField, SelectInput, Toolbar, SaveButton, DeleteButton, List, Datagrid, DateField, EditButton, TextInput, Edit, Create, SimpleForm} from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core'
import {countryList} from '../App'


const CountryTitle = ({ country }) => {
    return <span>Country {country ? `"${country.idLang}-${country.idCountry}"` : ''}</span>;
};



const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'start',
    },
};



const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        <DeleteButton />
    </Toolbar>
));


const CountrySimpleFormEdit = (props) => {  
    const menuItems = countryList.map((c) => (  <MenuItem value={c.lang+"-"+c.country}>{c.lang+"-"+c.country}</MenuItem>))  
    return (
    <SimpleForm {...props} >   
        <TextInput source="idLang" label="Lang"/> 
        <TextInput source="idCountry" label="Country"/> 
        <ReferenceInput label="Locale redirection (i.e: en-US)" source="redirection" reference="countries">
                <SelectInput optionText={(record)=> record.idLang+"-"+record.idCountry} />
        </ReferenceInput>
        
        <BooleanInput source="defaultLang"  label="Default lang"/>
        <BooleanInput source="learning"  label="Fast learning"/>
        <BooleanInput source="production"  label="Production"/>
    </SimpleForm>
    )
};

const CountryCode = (data)=> {
    
    return data.record.idLang+"-"+data.record.idCountry
}
//<SelectField optionText={(record)=> record.idLang+"-"+record.idCountry} />
const CountryList = props => {
    return (
    <List sort={{ field: 'rank', order: 'DESC' }} perPage={50} {...props}>
        <Datagrid  rowClick="edit">
            <TextField source="idLang" label="Lang"/>
            <TextField source="idCountry" label="Country"/>
            <ReferenceField label="Redirection" source="redirection" reference="countries">
                <CountryCode/>
            </ReferenceField>
            <BooleanField source="defaultLang" label="Default Lang"/>
            <BooleanField source="learning" label="Fast learning"/>
            <BooleanField source="production" label="Production"/>
            <DateField source="updatedAt" label="Updated At"/>
            <EditButton/>
        </Datagrid>
    </List>
    );
};

const CountryEdit = props => (
    <Edit title={<CountryTitle/>} {...props}>        
        <CountrySimpleFormEdit toolbar={<CustomToolbar />}/>        
    </Edit>
);

const CountryCreate = props => (
    <Create title={<CountryTitle/>}{...props}>        
            <CountrySimpleFormEdit redirect="list"/> 
    </Create>
);


export default {
     create: CountryCreate,
     edit: CountryEdit,
     list: CountryList,

};
