import * as React from 'react';
import ClearSharp from '@material-ui/icons/ClearSharp';
import { BulkUpdateButton } from 'react-admin';

const levels = { likes: 0, dislikes:0};

const BulkResetLikes = (props) => (
    <BulkUpdateButton
        {...props}
        label="Reset Likes"
        data={levels}
        icon={<ClearSharp/>}
    />
);

export default BulkResetLikes;