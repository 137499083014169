import * as React from 'react';
import { FC, Fragment } from 'react';
import {BulkDeleteButton, CreateButton, ExportButton, Pagination, TextField, SelectField, NumberField, Filter, List, Datagrid, DateField, EditButton, TextInput, downloadCSV } from 'react-admin';
import { makeStyles} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import { modeType } from '../simpleRestProvider'
import WithConfirm from '../components/WithConfirm';
import BulkCloneButton from '../components/BulkCloneButton';
import BulkSwapButton from '../components/BulkSwapButton';
import ImportButton from '../components/ImportButton';
import SwapLevelButton from '../components/SwapLevelButton';
import CardActions from '@material-ui/core/CardActions';
import { unparse as convertToCSV } from 'papaparse';
import BulkForceStatistics from '../components/BulkForceStatistics';
import BulkResetLikes from '../components/BulkResetLikes';
import checkWriteAllow from '../components/utils';
import { usePermissions } from 'react-admin';




const exporter = (levels:any) => {

    const levelsForExport = levels.map((level:any) => ({
        id: level.id,
        rank: level.rank,
        lang: level.lang,
        country: level.country,
        type: level.type,
        media: level.media,
        answers: level.answers,
    }
    ))        
    
    const csv = convertToCSV({
        data: levelsForExport,
        fields: ['id', 'rank', 'lang', 'country', 'type', 'media', 'answers' ]
    });
    downloadCSV(csv, 'levels'); // download as 'clients.csv` file
}


const LevelBulkActionButtons = ({write , ...props}:any) => (
    <Fragment>
        {write &&
        <>
        <BulkCloneButton label="Clone Levels" {...props} />
        <BulkSwapButton label="Swap" {...props} />
        <WithConfirm content="Reset likes stats ?"><BulkResetLikes {...props} /></WithConfirm>
        <WithConfirm  content="Disable predictive keyboard and force stats push"><BulkForceStatistics {...props} /></WithConfirm>
        <WithConfirm  content="Delete definitively this level ?"><BulkDeleteButton {...props} /></WithConfirm>
        </>
        }
    </Fragment>
);

const ButtonActions = ({ basePath, data, resource, total, maxResults, currentSort, filterValues, permanentFilter, write  }:any) => (
    <CardActions> 
       {write &&
        <>
        <ImportButton basePath={basePath} resource={resource} key='1' />
        <SwapLevelButton basePath={basePath} resource={resource} key='2'/>
        <CreateButton basePath={basePath} key='3'/>
        <ExportButton
             disabled={total === 0}
             resource={resource}
             sort={currentSort}
             filter={{ ...filterValues, ...permanentFilter }}
             exporter={exporter}
             maxResults={maxResults}
         />
         </>
        }
        
    </CardActions>
);





const LevelPagination = (props: any) => <Pagination rowsPerPageOptions={[100, 200, 500]} {...props} />

const useFilterStyles = makeStyles(theme => ({
    filter: {
        marginBottom: '64px',
    },
}));
const ListFilters = (props: any) => {
    const classes = useFilterStyles();
    return (
    <Filter {...props} className={classes.filter}>
        <TextInput source="ids" label="Id" alwaysOn/>
        <TextInput source="content" label="Content" alwaysOn/>
    </Filter>
)};


const RankCheck = (props:any) => {
    
    if(!(props?.record?.rankCheck)) {
        return (<Tooltip title="Discontinuité : niveau inaccessible !" aria-label="warning1"><WarningIcon style={{fontSize: 20, color: "#F00" }}/></Tooltip>)
    } else {
        return (<></>)
    }

}



const LearningComplete =  (props:any) => {

    if(props?.record?.mode === modeType.LEARNING) {
        if(props?.record?.priority) {
            return (<p style={{fontSize: 10, color: "#F00" }}> Learning in progress</p>)
        } else {
            return (<p style={{fontSize: 10, color: "#0F0" }}>Learning complete</p>)
        }
    } else return null
}

const LikePercentage =  (props:any) => {

    if(props?.record?.likes >= 1) {
        let percent = props.record.likes / (props.record.likes + props.record.dislikes) * 100.0
        let total = props.record.likes + props.record.dislikes
        if(percent < 70) {
            return (<><p style={{color: "#F00" }}> {percent.toFixed(2)} % </p><p style={{color: "#F00", fontSize: 9}}> ({total} votes) </p></>)
        } else {
            return (<><p style={{color: "#0F0" }}> {percent.toFixed(2)} % </p><p style={{color: "#0F0", fontSize: 9}}> ({total} votes) </p></>)
        }
    } else return null
}


const LevelList = (props:any) => {
    
    const lang = props.resource.split('/')[2]
    const country = props.resource.split('/')[4]
    const { loaded, permissions } = usePermissions();
    const write = checkWriteAllow(permissions, lang, country)
   
    if(loaded)
        return (        
            <List {...props} exporter={false} filters={<ListFilters />} pagination={<LevelPagination />} perPage={200} sort={{ field: 'rank', order: 'ASC' }} actions={<ButtonActions write={write}/>} bulkActionButtons={<LevelBulkActionButtons write={write}/>}>
                <Datagrid  rowClick={write?"edit":"show"}   optimized>            
                    <NumberField source="rank" label="Index"/>            
                    <RankCheck/>
                    <TextField source="media" label="Content" />  
                    <SelectField source="mode" choices={[
                    { id: modeType.LEARNING, name: 'Learning' },
                    { id: modeType.NORMAL, name: 'Production with statistics' },
                    { id: modeType.PREDICTIVE, name: 'Production' },
                    ]} />
                    <LearningComplete/>
                    <LikePercentage label="Likes percentage"/>
                    <DateField source="createdAt" label="Created at"/>
                    <EditButton/>
                </Datagrid>
            </List>
        )
    else
        return (<></>)
}


export default LevelList;
