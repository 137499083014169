import * as React from 'react';
import { FC, useRef } from 'react';


import Chip from '@material-ui/core/Chip';
import { EditComponentProps } from '../types';
import DisplayStatistics from '../components/DisplayStatistics';
import { makeStyles } from '@material-ui/core/styles';

import { Show } from 'react-admin';
import { NumberField } from 'react-admin';

import { SimpleShowLayout, TextField, Datagrid } from 'react-admin';



const drawerWidth = 600;
const useStyles = makeStyles({

    root: {

    },
    form: {
        width: '60%',
    }
})


const useFocus = () => {
    const htmlElRef = useRef<HTMLInputElement>(null)
    const setFocus = () => {
       
        htmlElRef?.current && htmlElRef.current.focus()
    }

    return [htmlElRef, setFocus]
}


const TextArrayField = ({ record, source }:any) => (
    <>

        {record[source].map((item: React.Key | null | undefined) => <div><Chip label={item} key={item} /></div>)}
    </>
)
TextArrayField.defaultProps = {
    addLabel: true,
};


const LevelShow: FC<EditComponentProps> = ({ permissions, ...props }) => {
    const classes = useStyles();


    return (

        <Show className={classes.root} {...props} >
            <SimpleShowLayout>

                <NumberField source="rank" label="Index"  />
                
                <TextField  source="media" label="Theme" />
                      
                <TextArrayField source="answers" label="Answers" />
               
            </SimpleShowLayout>
        </Show>

    );
};

export default LevelShow;
