// in ./ResetViewsButton.js
import * as React from 'react';
import { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import IconCancel from '@material-ui/icons/Cancel';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MenuItem from '@material-ui/core/MenuItem';
import {
    fetchUtils,
    Button,
    Confirm,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';

const useStyles = makeStyles((theme) => ({
 
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const BulkSwapButton = (props) => {
    const { resource, showNotification,refreshView , selectedIds} = props;
    const { fetchJson } = fetchUtils;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [local, setLocal] = useState('');
    
  
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();


   
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async () => {

        
        const lang = resource.substr(12,2)
        const country = resource.substr(23,2)
        
        const access_token= localStorage.getItem('token')

        setOpen(false);
    
        try{
        
            await fetchJson(`${process.env.REACT_APP_API_URL}/levels/pairswap`, {method: 'PUT', body: JSON.stringify({lang:lang, country:country, id1:selectedIds[0], id2:selectedIds[1] , access_token})})
            refresh();
            notify(`Levels  swapped`);
            unselectAll(resource);
                
        } catch(e) {
                notify('Error: swap failed', 'warning')
        }
             
        
    };



    return (
        <Fragment>
            <Button disabled={selectedIds.length !== 2} label="Swap" onClick={handleClick}><SwapVerticalCircleIcon /></Button>
            <Dialog
                    fullWidth
                    open={open}
                    onClose={handleDialogClose}
                    aria-label="Swap Levels"
                >
                    <DialogTitle>Swap Levels</DialogTitle>
                    <DialogContent>
                    
                        <p>Do you want to swap these 2 levels ? </p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            label="ra.action.confirm"
                            onClick={handleConfirm}
                        >
                            <SwapVerticalCircleIcon />
                        </Button>
                        <Button
                            label="ra.action.cancel"
                            onClick={handleDialogClose}
                        >
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
        </Fragment>
    );
}

export default BulkSwapButton;