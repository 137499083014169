// in src/comments/ApproveButton.js
import React, { FC, useEffect, useState } from 'react';
import { Button } from 'react-admin';
import {useForm} from 'react-final-form'
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import levenshtein from 'js-levenshtein';
import { makeStyles } from '@material-ui/core/styles';
import removeAccents from 'remove-accents';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles({
   
    aside: {
        display: 'flex',
        flexDirection: 'column',
        width:'300px',
        marginTop:'50px'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column'
    },
    answer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    textField: {
        marginLeft: '10px',
        marginRight: '10px',
    },
    chip: {
        width: '100%',
        margin: '2px 3px 2px 0',
        color: 'primary'
    }
});

const accentsMap:{[key: string]: string} = {
    a: 'á|à|ã|â|À|Á|Ã|Â',
    e: 'é|è|ê|É|È|Ê',
    i: 'í|ì|î|Í|Ì|Î',
    o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
};
  
const slugify = (text:string) => Object.keys(accentsMap).reduce((acc, cur) => acc.replace(new RegExp(accentsMap[cur], 'g'), cur), text);


const DisplayStatistics:FC<any> = (props:any) => {

    const classes = useStyles();
    const [data, setData] = useState(new Array<any>());
    const [totalSumAnswer, setTotalSumAnswer] = useState(0);
    const [simpleStat, setSimpleStat] = useState(true);
    const [mergeLeven, setMergeLeven] = useState(true);
    const form = useForm();
   
    useEffect(() => {
        renderStatistics(props.record.id)
      }, [simpleStat, mergeLeven]);

    const renderStatistics = (id:string) => {


        if(id) {
        const access_token = localStorage.getItem('token')

        fetch(`${process.env.REACT_APP_API_URL}/levels/stats/${id}?simple=${simpleStat}&access_token=${access_token}`)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson && responseJson.stats) {
                    const totalSumAnswer = responseJson.stats.reduce((sum:number, currentData:any) => sum + parseInt(currentData.total), 0)
                    
                    responseJson = responseJson.stats.map((a:any) => {
                        return {
                            total: a.total,
                            _id: a._id,
                            percentage: a.percentage
                        }
                    })
                    responseJson = responseJson.reduce((unique:any, answer:any) => unique.includes(answer) ? unique : [...unique, answer], [])
                    let mergedStats = mergeLeven?levenshteinFilter(responseJson):responseJson
                    setColorForAnswers(mergedStats)
                    setData(mergedStats)
                    setTotalSumAnswer(totalSumAnswer)
                   
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }

    }


    const handleAddAnswer = (answer:string, percentage:number) => (evt:any) => {
                
        //let answers = form.getState().values.answers;
        //let answers = props.record.answers
        //answers.push(answer)     
        //form.resetFieldState('answers')
        if(form)
            form.mutators.push('answers', answer)   
       // props.setAnswers()
        
    }

    const removeAccentAndPlural = (word:string) => {
        word = removeAccents(word)
        // Length check
        if (word.length < 1) return word

        // Rule: ending with 's'
        if (word[word.length - 1] === 's')
        {
            return word.slice(0, -1)
        }

        return word

    }



    const levenshteinFilter = (source:Array<any>, maximum = 1) => {
        
        let _source:Array<any>, matches:Array<any>, x:number, y:number; 
        _source = source.reverse();        
        matches = [];
        for (x = _source.length - 1; x >= 0; x--) {
            let output:any = _source.splice(x, 1)[0];

            for (y = _source.length - 1; y >= 0; y--) {
                if (removeAccentAndPlural(output._id.toUpperCase()) === removeAccentAndPlural(_source[y]._id)) {
                    output.percentage += _source[y].percentage;
                    _source.splice(y, 1);
                    x--;
                }
            }

            if(output._id.length > 3) {          
                for (y = _source.length - 1; y >= 0; y--) {
                    if (levenshtein(output._id.toUpperCase(), _source[y]._id.toUpperCase()) <= maximum ) {
                        output.percentage += _source[y].percentage;
                        _source.splice(y, 1);
                        x--;
                    }
                }
            }
            matches.push(output);
        }
        matches.sort((a,b) => b.percentage-a.percentage)

        //setColorForAnswers(matches)
        
        return matches;
    }


    const setColorForAnswers = (data:any) => {
        
        if(form) {
            let answers = form.getState().values.answers;
            let foundAnswers: any[] = data?.filter((answer:any) => {
                    
                    let found = answers?.find((e:any)=>{
                        if(answer._id.length > 3)
                            return levenshtein(removeAccents(e.toUpperCase()),answer._id.toUpperCase())<=1
                        else
                            return removeAccentAndPlural(e.toUpperCase()) === removeAccentAndPlural(answer._id.toUpperCase())
                    })
                    if(found && found.length>0) return true
                    return false
            })


            //find min percentage in foundAnswers
            let minPercentage = foundAnswers.reduce((min:number, current:any) => Math.min(min, current.percentage), 100)

            //set the color for the found answers
            data.forEach((answer:any) => {if(answer.percentage > minPercentage) answer.color = "#FF7F00" })
            foundAnswers.forEach((answer:any) => {
                answer.color = "#00FF00"
            })

        }

    }


    const label = { inputProps: { 'aria-label': 'Only first answers' } };
    return (
        <Drawer open={true} variant="persistent" anchor="right" >
        <div className={classes.aside}>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel
                            control={ <Switch  { ...label} onChange={() => {setSimpleStat(!simpleStat)}} checked={!simpleStat}/> }
                            label="Only first given answers"
                        />
                        <FormControlLabel
                            control={ <Switch  { ...label} onChange={() => {setMergeLeven(!mergeLeven)}} checked={mergeLeven} color="primary"/> }
                            label="Merge similars"
                        />
                    </FormGroup>
                </FormControl>
                <Chip
                    className={classes.chip}
                    label={"Total : " + totalSumAnswer}
                />
                
                <Paper className={classes.paper}>
                    {
                        data && data.map((w,index) => {
                            const answer = w._id
                            const color = w.color?w.color:"#DDDDDD"

                            return (<span key={index} className={classes.answer}><Chip
                                style={{ width: '100%', margin: '1px 1px 1px 0', backgroundColor: color }} avatar={<Avatar style={{ width: '50px', backgroundColor:'#FFFFFF', fontWeight:'bold' }} variant="square">{w.percentage.toFixed(2)}%</Avatar>}
                                label={answer}
                            />
                                <Button style={{ marginBottom: '5px' }} label="+" onClick={handleAddAnswer(answer, w.percentage.toFixed(2))} />
                            </span>
                            )

                        })
                    }
                </Paper>

            </div>
            </Drawer>
    );
    
}
//<Button style={{ marginBottom: '5px' }} label="Merge levenstein 1" onClick={handleMergeSimilarAnswer}/>

export default DisplayStatistics;