import { AuthProvider } from 'ra-core';


const authProvider: AuthProvider = {
    login: ({ username, password}) => {
        const request = new Request(process.env.REACT_APP_API_URL+"/auth", {
            method: 'POST',
            headers: new Headers({ 
                'Content-Type': 'application/json',
                'Authorization': 'Basic '+btoa(username+':'+password)
            }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }                
                return response.json();
            })
            .then((resp) => {
               
                localStorage.setItem('token', resp.token);         
                localStorage.setItem('role', resp.user.role);  
                if(resp.user.allow)localStorage.setItem('allow', JSON.stringify(resp.user.allow))
                localStorage.setItem('userId', resp.user.id);            
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkError: ({status}) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: async () => {
        const role = localStorage.getItem('role');
        const allow = localStorage.getItem('allow');
       
        if(allow === null || allow === undefined)
            return {role, allow:[]};
        else
            return {role, allow :JSON.parse(allow)};
            
    },
    getIdentity: () =>
        Promise.resolve({
            id: '',
            fullName: '',
            avatar: ''
        }),
};

export default authProvider;