import React from 'react';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { connect } from 'react-redux';
import { parse as convertFromCSV } from 'papaparse';
import { crudCreate, refreshView, showNotification } from 'ra-core';


const ImportButton = (props) => {
    const { resource, dispatch, basePath } = props;

    const handleImport = (e) => {
    
        const file = e.target.files && e.target.files[0];

        if (file) {
            convertFromCSV(file, {
                delimiter: ',',
                complete: (result) => {
                    const { data } = result;
                
                    data.forEach(row => {
                        if(row.length>1) {
                            let crudMessage = crudCreate(resource, {rank: row[0], type: 'theme', media: row[1], mode: 'learning' }, basePath, false)
                            crudMessage.meta.onSuccess.notification= undefined
                            dispatch(
                                crudMessage
                            )
                        }
                        }
                    )

                    setTimeout(function () {
                        dispatch(refreshView())
                        dispatch(showNotification("Levels created !"))
                    }, 1000);
                   
                }
            });
        }

        
        
    };

    return (
        <>
            <input
                type="file"
                id="import-button"
                style={{ display: 'none' }}
                accept='.csv'
                onChange={handleImport}
            />
            <label htmlFor="import-button" style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Button
                    color="primary"
                    component="span"
                >
                    <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: 20 }} />
                    <span style={{ paddingLeft: '0.5em' }}>Import</span>
                </Button>
            </label>
        </>
    );
};


export default connect()(ImportButton);